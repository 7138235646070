import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Spinner from "react-bootstrap/Spinner";
import { withTranslation } from "react-i18next";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";

class Fakturering extends Component {
  state = {
    charges: undefined,
    info: {},
  };

  componentDidMount() {
    if (
      firebase.auth().currentUser !== null &&
      this.state.charges === undefined
    ) {
      this.updateCharges();
    }
  }

  updateCharges() {
    let user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("owners")
      .where("email", "==", user.email)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let customer = doc.data().stripeCustomerId;
          const getReceipts = firebase.functions().httpsCallable("getReceipts");
          getReceipts({ customer: customer })
            .then((result) => {
              this.setState({ charges: result.data.charges.data });
            })
            .catch((e) => {
              console.log(e);
            });
        });
      });
  }
  handleChange() {
    document.getElementById("saveButton").style.visibility = "visible";
    document.getElementById("saveButton").style.opacity = "1";
  }

  handleSaveData() {
    document.getElementById("saveButton").style.opacity = "0";
    setTimeout(function () {
      document.getElementById("saveButton").style.visibility = "hidden";
    }, 1000);
  }

  render() {
    const { t } = this.props;
    let charges = null;
    if (this.state.charges !== undefined) {
      charges = this.state.charges.map((item, index) => {
        var receiptDate = new Date(item.created * 1000);
        let day =
          receiptDate.getDate() > 10
            ? receiptDate.getDate()
            : "0" + receiptDate.getDate();
        let month =
          receiptDate.getMonth() + 1 > 10
            ? receiptDate.getMonth() + 1
            : "0" + (receiptDate.getMonth() + 1);
        let year = receiptDate.getFullYear();
        return (
          <div className="invoiceOuter">
            <div className="invoiceBox box1">
              <p className="billingInfo">
                {day + " / " + month + " / " + year}
              </p>
            </div>
            <div className="invoiceBox box2">
              <p className="billingInfo">{t("billing.subcription")}</p>
            </div>
            <div className="invoiceBox box3">
              <p className="billingInfo">
                {item.amount / 100 + " " + item.currency}
              </p>
            </div>
            <div className="invoiceBox box4">
              <a
                className="linkStyle underline billingInfo"
                href={item.receipt_url}
              >
                {t("billing.download")}
              </a>
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        <Helmet>
          <style>{"body { background-color: #F7F7F7 !important; }"}</style>
        </Helmet>
        <div className="saveDiv">
          <a
            id="saveButton"
            className="yellowBtn linkStyle saveButton boxShadow"
            onClick={this.handleSaveData}
          >
            {t("billing.save")}
          </a>
        </div>
        <p className="tabTitle">{t("billing.billing")}</p>
        <div className="content contentWide boxShadow">
          <div className="billingDiv">
            {/*
                        <p className="contentTitle topTitle">Company</p>
                        <input className="longInput billingInput" type="text" placeholder="" onChange={this.handleChange}></input>
                        <div className="infoDiv">
                            <p className="infoText">If you belong to a Company, it will be added to billing receipts.</p>
                        </div>
                        <p className="contentTitle">Company profile</p>
                        <textarea className="blockInput billingInput" type="text" placeholder="" onChange={this.handleChange}></textarea>
                        <div className="infoDiv">
                            <p className="infoText">Additional company information to be shown on invoices; e.g. address, PO number, etc.</p>
                        </div>
                        <hr className="lineBreakExtend"></hr>
                        <p className="contentTitle topTitle">Active card</p>
                        <div className="cardDiv">
                            <div className="cardInfoDiv">
                                <p className="billingInfo">Per Hammar - MasterCard - XXXX-XXXX-XXXX-XXXX</p>
                                <p className="billingInfo">SE</p>
                            </div>
                            <div className="expireDiv">
                                <p className="billingInfo">Går ut:</p>
                                <p className="billingInfo">07/23</p>
                            </div>
                        </div>
                        <div className="cardBtnDiv">
                            <a className="cardBtn yellowBtn linkStyle">Update card</a>
                        </div>
                        <hr className="lineBreakExtend"></hr>*/}
            <p className="contentTitle topTitle">{t("billing.pastInvoices")}</p>
            {!charges && (
              <p className="noInvoicesText">{t("billing.noPast")}</p>
            )}
            {charges}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Fakturering);
