import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "./images/logoFull.png";
import mockup from "./images/mockupfinal.png";
import fblogo from "./images/fblogo.png";
import googlelogo from "./images/googlelogo.png";
import applelogo from "./images/apple.png";
import googlePlay from "./images/googlePlay.png";
import appstore from "./images/appstore.png";
import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import YouTube from "./images/youtube.png";
import tiktok from "./images/tiktok.png";
import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import axios from "axios";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";

import AppleLogin from "react-apple-login";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorText: "",
      flag: false,
      facebook: false,
    };
  }

  componentDidUpdate() {
    if (this.state.facebook == true) {
      this.props.history.push("/dashboard");
    }
  }

  signInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    const auth = firebase.auth.getAuth();
    firebase.auth
      .signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        console.log("RESULT=> ", result);
        const user = result.user;

        // Apple credential
        const credential =
          firebase.auth.OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential =
          firebase.auth.OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  signInWithFacebook = async () => {
    var isValid = false;
    setTimeout(() => {
      if (isValid == true) {
        this.props.history.push("/dashboard");
      }
    }, 5000);

    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        const uid = result?.user?.uid;
        const fname = result?.additionalUserInfo?.profile?.first_name;
        const lname = result?.additionalUserInfo?.profile?.last_name;
        const email =
          result?.user?.email?.length > 0
            ? result?.user?.email
            : "sumerPal@gmail.com";
        const ref = firebase.firestore().collection("owners").doc(uid);
        ref.get().then((doc) => {
          if (!doc?.exists) {
            ref.set({
              fname: fname,
              lname: lname,
              email: email.toLowerCase(),
              stripeCustomerId: "gfgfd",
              bars: [],
            });
            isValid = true;
          } else {
            isValid = true;
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  signInWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const uid = result?.user?.uid;
        const fname = result?.additionalUserInfo?.profile?.given_name;
        const lname = result?.additionalUserInfo?.profile?.family_name;
        const email = result?.additionalUserInfo?.profile?.email;
        const ref = firebase.firestore().collection("owners").doc(uid);
        ref.get().then((doc) => {
          console.log("====================================");
          console.log("LoginDatat====>" + JSON.stringify(doc.data()));
          console.log("====================================");
          if (!doc.exists) {
            ref.set({
              fname: fname,
              lname: lname,
              email: email.toLowerCase(),
              stripeCustomerId: " ",
              bars: [],
            });
            this.props.history.push("/dashboard");
          } else {
            if (doc?.data()?.deleted == true) {
              ref.set({
                fname: doc?.data()?.fname,
                lname: doc?.data()?.lname,
                email: doc?.data()?.email.toLowerCase(),
                stripeCustomerId: doc?.data()?.stripeCustomerId,
                bars: doc?.data()?.bars,
                deleted: false,
                deletedAt: "",
              });

              if (doc?.data()?.bars?.length > 0) {
                const barIds = doc?.data()?.bars;
                barIds.map((barId) => {
                  return firebase
                    .firestore()
                    .collection("bars")
                    .doc(barId)
                    .update({
                      deleted: false,
                      deletedAt: "",
                    });
                });
              }
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/dashboard");
            }
          }
        });

        // this.props.history.push("/dashboard");
      });
  };

  appleResponse = (response) => {
    if (!response.error) {
      axios
        .post("/auth", response)
        .then((res) => this.setState({ authResponse: res.data }))
        .catch((err) => console.log(err));
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="outerOnBoarding">
        <Helmet>
          <style>{""}</style>
        </Helmet>
        <div className="logoDiv">
          <img className="logoOnBoarding" src={logo} alt="logo"></img>
        </div>
        <img className="mockup" src={mockup} alt="mockup"></img>
        <div className="innerOnBoarding">
          <p className="title">{t("landing.welcome")}</p>
          <p className="introText">{t("landing.info1")}</p>
          <p className="introText">{t("landing.info2")}</p>
          <p className="introTextRed">{t("landing.info4")}</p>
          <div className="tutorialLable">
            <p className="introTexts">{t("landing.tutorialLink")}</p>
            <a href="https://www.youtube.com/@barzone8731" target="_blank">
              <p className="introText">{t("landing.clickhere")}</p>
            </a>
          </div>
          <NavLink to="/create-account">
            <button className="landingButton">{t("landing.signUp")}</button>
          </NavLink>
          <p className="accountInquiry">{t("landing.alreadyMember")}</p>
          <NavLink to="/login">
            <a className="logInLink link">{t("landing.logIn")}</a>
          </NavLink>
          <NavLink to="/forgot-password">
            <a className="logInLink link">{t("landing.forgotPass")}</a>
          </NavLink>

          <div className="social_btn">
            <img
              className="social_logo"
              src={googlelogo}
              alt="Sign with Google"
              onClick={() => this.signInWithGoogle()}
            />
            <img
              className="social_logo"
              src={fblogo}
              alt="Sign with Facebook"
              onClick={() => this.signInWithFacebook()}
              // onClick={() => this.signInWithApple()}
            />

            <AppleLogin
              clientId="com.services.barzone"
              redirectURI="https://barzone-e3783.firebaseapp.com/__/auth/handler"
              usePopup={true}
              scope="name email"
              onError={(error) =>
                console.log("AppleSignInBtn onError error", error)
              }
              render={(renderProps) => (
                <img
                  className="apple_social_logo"
                  src={applelogo}
                  alt="Sign with Apple"
                  onClick={renderProps.onClick}
                />
              )}
            />

            {/* <AppleLogin
              clientId="com.services.barzone"
              redirectURI="https://barzone-e3783.firebaseapp.com/__/auth/handler"
              responseType="code"
              responseMode=""
              response={this.appleResponse}
              render={(props) => (
                <button onClick={props.onClick} disabled={props.disabled}>
                  Sign in with Apple
                </button>
              )}
            /> */}

            {/* <AppleLogin
              clientId="com.services.barzone"
              redirectURI="https://barzone-e3783.firebaseapp.com/__/auth/handler"
              usePopup={true}
              callback={this.appleResponse} // Catch the response
              scope="email name"
              responseMode="query"
              render={(
                renderProps //Custom Apple Sign in Button
              ) => (
                <button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "white",
                    padding: 10,
                    border: "1px solid black",
                    fontFamily: "none",
                    lineHeight: "25px",
                    fontSize: "25px",
                  }}
                >
                  <i className="fa-brands fa-apple px-2 "></i>
                  Continue with Apple
                </button>
              )}
            /> */}
          </div>
        </div>
        <div className="footer">
          <NavLink className="footerLink" to="/privacy">
            <a className="footerLink">{t("landing.privacy")}</a>
          </NavLink>
          <NavLink className="footerLink" to="/aboutus">
            <a className="footerLink">{t("landing.aboutUs")}</a>
          </NavLink>
          <a href={"mailto:support@bar-zone.com"} className="footerLink">
            {t("Contact us")}
          </a>
        </div>
        <div className="storeButtonDiv">
          <a href="https://play.google.com/store/apps/details?id=barzone1.android">
            <img
              className="storeButtons"
              src={googlePlay}
              alt="google play"
            ></img>
          </a>
          <a href="https://apps.apple.com/se/app/barzone/id1511489019?l=en">
            <img className="storeButtons" src={appstore} alt="app store"></img>
          </a>
          <div className="socialButtonDiv">
            <a href="https://www.facebook.com/barzones/" target="_blank">
              <img className="social_link_btn" src={facebook} />
            </a>
            <a href="https://www.instagram.com/barzones/" target="_blank">
              <img className="social_link_btn" src={instagram} />
            </a>
            <a
              href="https://www.tiktok.com/@barzones?_t=8dW2w0EpP3r&_r=1"
              target="_blank"
            >
              <img className="social_link_btn" src={tiktok} />
            </a>
          </div>
        </div>

        {/* <div className="socialButtonDiv">
          <a href="https://www.facebook.com/barzones/" target="_blank">
            <img className="social_link_btn" src={facebook} />
          </a>

          <a href="https://www.instagram.com/barzones/" target="_blank">
            <img className="social_link_btn" src={instagram} />
          </a>
          <a
            href="https://www.tiktok.com/@barzones?_t=8dW2w0EpP3r&_r=1"
            target="_blank"
          >
            <img className="social_link_btn" src={tiktok} />
          </a>
          <a href="https://www.youtube.com/@barzone8731" target="_blank">
            <img
              className="social_link_btn"
              src={YouTube}
              onClick={() => this.signInWithApple()}
            />
          </a>
        </div> */}
      </div>
    );
  }
}

export default withTranslation()(Landing);
