import React, { Component, Suspense } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Landing from "./Landing";
import RegAccount from "./RegAccount";
import RegBar from "./RegBar";
import RegSub from "./RegSub";
import LogIn from "./LogIn";
import ForgotPass from "./ForgotPass";
import Main from "./Main";
import Dashboard from "./Dashboard";
import Privacy from "./Privacy";
import AboutUs from "./AboutUs";
import firebase from "firebase/compat/app";
var firebaseConfig = {
  apiKey: "AIzaSyBrxJh-G4onyvBIhurl9YyvyHaTAvHZSzc",
  authDomain: "barzone-e3783.firebaseapp.com",
  databaseURL: "https://barzone-e3783.firebaseio.com",
  projectId: "barzone-e3783",
  storageBucket: "barzone-e3783.appspot.com",
  messagingSenderId: "565086923237",
  appId: "1:565086923237:web:365b7a496e68753682270c",
  measurementId: "G-DXY4EPEW72",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

class OnBoarding extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Suspense fallback={<div>Loading</div>}>
          <Router>
            <Route exact path="/" component={Landing} />
            <Route path="/create-account" component={RegAccount} />
            <Route path="/register-bar" component={RegBar} />
            <Route path="/pick-subscription" component={RegSub} />
            <Route path="/login" component={LogIn} />
            <Route path="/forgot-password" component={ForgotPass} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/overview" component={Main} />
            <Route path="/events" component={Main} />
            <Route path="/billing" component={Main} />
            <Route path="/subscription" component={Main} />
            <Route path="/event" component={Main} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/AboutUS" component={AboutUs} />
          </Router>
        </Suspense>
      </div>
    );
  }
}

export default OnBoarding;
