import React, { Component } from "react";
import { Switch, Route, NavLink, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "./images/logo.png";
import logoHeader from "./images/logoheader.png";
import logoHeaderSvg from "./images/logoheadersvg.svg";
import Overview from "./Overview.js";
import Events from "./Events.js";
import Event from "./Event.js";
import Fakturering from "./Fakturering.js";
import Prenumeration from "./Prenumeration.js";
import arrow from "./icons/arrow-down.png";
import arrowWhite from "./icons/arrow-down-white.png";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import firebase from "firebase/compat/app";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "proxima-nova, sans-serif",
  },
}))(Tooltip);

let lastScrollY = 0;
//let ticking = false;

class Main extends Component {
  constructor() {
    super();
    this.state = {
      page: "",
      overlay: false,
      offCanvas: false,
      barInfo: {
        trial: true,
      },
      uid: "",
      userEmail: "",
      scrollClass: "menuInitial",
      eventInfo: "",
      visible: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.pageChange = this.pageChange.bind(this);
  }

  componentDidMount() {
    const eventData = localStorage.getItem("event");
    const eventInfo = JSON.parse(eventData);
    this.setState({
      eventInfo: eventInfo,
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const email = user.email;

        const data = localStorage.getItem("info");
        this.setState({ barInfo: JSON.parse(data), userEmail: email });
      } else {
        this.props.history.push("/login");
      }
    });

    window.addEventListener("scroll", this.handleScroll, true);

    switch (this.props.history.location.pathname) {
      case "/overview":
        this.setState({
          page: "Overview",
        });
        break;
      case "/events":
        this.setState({
          page: "Events",
        });
        break;
      case "/Event":
        this.setState({
          page: "Events",
        });
        break;
      case "/billing":
        this.setState({
          page: "Billing",
        });
        break;
      case "/subscription":
        this.setState({
          page: "Subscription",
        });
        break;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  nav = React.createRef();

  handleScroll = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 203) {
      this.setState({
        scrollClass: "menuFixed",
      });
    } else if (lastScrollY <= 203) {
      this.setState({
        scrollClass: "menuInitial",
      });
    }
  };

  handleClick(e) {
    e.preventDefault();
    this.setState({
      scrollClass: "menuInitial",
    });
    /*
        document.getElementById("menu").style.position = "initial";
        document.getElementById("menu").style.top = "initial";
        */
  }

  pageChange(title) {
    document.getElementById("dropdown-basic").blur();
    this.setState({ page: title });
  }

  toggleDropdown() {
    window.scrollTo(0, 0);
    this.setState({ overlay: !this.state.overlay });
    this.setState({ spin: !this.state.spin });
    document.getElementById("dropdown-basic").blur();

    if (!this.state.overlay) {
      document.body.style.overflow = "hidden";
      document.getElementById("arrow").style.animation =
        "0.5s ease 0s spinUp forwards";
    } else {
      document.body.style.overflow = "initial";
      document.getElementById("arrow").style.animation =
        "0.5s ease 0s spinDown forwards";
    }
  }

  toggleOffCanvas() {
    this.setState({ offCanvas: !this.state.offCanvas });
    if (!this.state.offCanvas) {
      document.getElementById("offCanvas").style.right = "0px";
    } else {
      document.getElementById("offCanvas").style.right = "-300px";
    }
  }

  getTrialDays() {
    let created = this.state.barInfo.created;
    let trial = this.state.barInfo.trial;
    let sub = this.state.barInfo.subscription;
    let diff = 0;
    if (created && trial) {
      if (created.seconds) {
        diff =
          30 -
          (new Date().getTime() / (1000 * 60 * 60 * 24) -
            created.seconds / (60 * 60 * 24));
      } else {
        diff =
          30 -
          (new Date().getTime() / (1000 * 60 * 60 * 24) -
            new Date(created).getTime() / (1000 * 60 * 60 * 24));
      }
      if (diff >= 0) {
        // if (diff > 5) {
        //   return <a className="greenBtn">Trial days left: {Math.ceil(diff)}</a>;
        // } else {
        //   return <a className="redBtn">Trial days left: {Math.ceil(diff)}</a>;
        // }
      } else {
        let barInfo = this.state.barInfo;
        const ref = firebase.firestore().collection("bars").doc(barInfo.id);
        ref.update({
          trial: false,
        });
        barInfo.trial = false;
        localStorage.setItem("info", JSON.stringify(barInfo));
      }
    }
    // if (sub == 0) {
    //   return <a className="greyBtn">Trial has expired</a>;
    // }
  }

  softDeleteSpecificBar = async () => {
    const barData = await localStorage.getItem("info");
    const finalDataParse = JSON.parse(barData);

    const uid = firebase.auth().currentUser.uid;
    const barID = finalDataParse?.id; // Assuming barID is directly available

    if (!barID) {
      console.error("Bar ID not found in local storage");
      return;
    }

    try {
      // Fetch the owner's document
      const ownerDoc = await firebase
        .firestore()
        .collection("owners")
        .doc(uid)
        .get();

      console.log("OwnerDoc==>" + JSON.stringify(ownerDoc));

      if (ownerDoc.exists) {
        // Soft delete the specific bar document
        await firebase.firestore().collection("bars").doc(barID).update({
          deleted: true,
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        this.props.history.push("/dashboard");
      }
    } catch (error) {
      console.error("Error soft deleting the specific bar: ", error.message);
    }
  };

  softDeleteAllBars = async () => {
    const uid = firebase.auth().currentUser.uid;
    console.log("uid==>" + JSON.stringify(uid));

    try {
      // Fetch the owner's document
      const ownerDoc = await firebase
        .firestore()
        .collection("owners")
        .doc(uid)
        .get();

      console.log("OwnerDoc==>" + JSON.stringify(ownerDoc));

      if (ownerDoc.exists) {
        const barIds = ownerDoc.data().bars;

        // Soft delete each bar document
        const barSoftDeletions = barIds.map((barId) => {
          return firebase.firestore().collection("bars").doc(barId).update({
            deleted: true,
            deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });

        await Promise.all(barSoftDeletions);

        this.props.history.push("/dashboard");
      }
    } catch (error) {
      console.error("Error soft deleting bars: ", error.message);
    }
  };

  openModal = () => this.setState({ visible: true });
  closeModal = () => this.setState({ visible: false });

  render() {
    const { t } = this.props;
    let hamburgerStyle = this.state.offCanvas
      ? "hamburger hamburger--collapse is-active"
      : "hamburger hamburger--collapse";
    return (
      <div>
        <Helmet>
          <style>{"body { background-color: #F7F7F7 !important; }"}</style>
        </Helmet>
        <div className="header">
          <div className="logoBar">
            <div className="logo-content">
              <img className="logo" src={logo} alt="Bar Zone logo"></img>
            </div>
            <div className="logoHeader-content">
              <img
                className="logoHeader"
                src={logoHeaderSvg}
                alt="Bar Zone logo"
              ></img>
            </div>

            <div className="settingsNav-content">
              <Dropdown className="settingsNav">
                <Dropdown.Toggle
                  className="settingsToggle"
                  variant="success"
                  id="dropdown-basic"
                >
                  {this.state.userEmail}
                  <img
                    className="arrowDownWhite"
                    src={arrowWhite}
                    alt="arrow"
                  ></img>
                </Dropdown.Toggle>
                <Dropdown.Menu className="settingsDrop">
                  {/*<Dropdown.Item className="settingsItem item1">Settings</Dropdown.Item>*/}
                  <Dropdown.Item
                    className="settingsItem item2"
                    onClick={() => firebase.auth().signOut()}
                  >
                    {t("main.logOut")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <button
                class={hamburgerStyle}
                type="button"
                onClick={this.toggleOffCanvas.bind(this)}
              >
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
          <div id="offCanvas" className="offCanvas">
            <div className="offCanvasInner">
              <div className="offCanvasLinkDiv">
                {/*<a className="offCanvasLink">Settings</a>*/}
                <a
                  className="offCanvasLink"
                  onClick={() => firebase.auth().signOut()}
                >
                  {t("main.logOut")}
                </a>
              </div>
            </div>
            <div className="offCanvasLogoDiv">
              <img className="logoSmall" src={logo} alt="Bar Zone logo"></img>
            </div>
          </div>
          <div className="titleBar boxShadow">
            <div className="outerTitleBar">
              <div className="innerTitleBar">
                <p className="pageTitle">{this.state.barInfo.name}</p>
                <NavLink
                  to="/dashboard"
                  className="yellowBtn floatRight linkStyle"
                >
                  {t("main.dashboard")}
                </NavLink>
                {this.getTrialDays()}
              </div>
            </div>
          </div>
        </div>
        <BrowserRouter>
          <div className="outer">
            <div className="inner">
              <Dropdown
                className="dropdownMobile"
                onClick={this.toggleDropdown.bind(this)}
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <p className="dropdownToggle">{this.state.page}</p>
                  {/*
                                    {this.state.page=="Events" &&
                                        <p className="dropdownToggle">{this.state.eventInfo.title}</p>
                                    }*/}
                  <img
                    id="arrow"
                    className="arrowDown"
                    src={arrow}
                    alt="arrow"
                  ></img>
                  <Dropdown.Menu className="dropdownMenu">
                    <div className="overlayShow">
                      <NavLink
                        to="/overview"
                        className="showButton"
                        onClick={() => this.pageChange("Overview")}
                      >
                        {t("main.overview")}
                      </NavLink>
                      <NavLink
                        to="/events"
                        className={`showButton ${
                          this.state.barInfo.subscription > 0 ||
                          this.state.barInfo.trial
                            ? ""
                            : " "
                        }`}
                        onClick={() => this.pageChange("Events")}
                      >
                        {t("main.events")}
                      </NavLink>
                      <NavLink
                        to="/billing"
                        className={`showButton ${
                          this.state.barInfo.trial ? "disabled" : ""
                        }`}
                        onClick={() => this.pageChange("Billing")}
                      >
                        {t("main.billing")}
                      </NavLink>
                      <NavLink
                        to="/subscription"
                        className={`showButton `}
                        onClick={() => this.pageChange("Subscription")}
                      >
                        {t("main.subscription")}
                      </NavLink>
                    </div>
                  </Dropdown.Menu>
                </Dropdown.Toggle>
              </Dropdown>
              <div className="menu-contentbox">
                <div id="menu" className="menuInitial" ref={this.nav}>
                  <ul className="navigation" onClick={this.handleClick}>
                    <li className="boxShadow">
                      <NavLink
                        className="navSelect"
                        onClick={() => this.pageChange("Overview")}
                        to={{
                          pathname: "/overview",
                          info: this.state.info,
                        }}
                      >
                        {t("main.overview")}
                      </NavLink>
                    </li>
                    {/* <CustomTooltip
                      disableHoverListener={
                        this.state.barInfo.subscription > 0 ||
                        this.state.barInfo.trial
                      }
                      disableTouchListener={
                        this.state.barInfo.subscription > 0 ||
                        this.state.barInfo.trial
                      }
                      disableFocusListener={
                        this.state.barInfo.subscription > 0 ||
                        this.state.barInfo.trial
                      }
                      className="tooltipSub"
                      title={t("main.upgradeSub")}
                      placement="top"
                      arrow
                    > */}
                    <div>
                      <li
                        // className={`boxShadow ${
                        //   this.state.barInfo.subscription > 0 ||
                        //   this.state.barInfo.trial
                        //     ? ""
                        //     : "disabled"
                        // }`}

                        className={`boxShadow`}
                      >
                        <NavLink
                          className="navSelect"
                          onClick={() => this.pageChange("Events")}
                          to={{
                            pathname: "/events",
                            info: this.state.info,
                          }}
                        >
                          {t("main.events")}
                        </NavLink>
                      </li>
                    </div>
                    {/* </CustomTooltip> */}
                    {/* <CustomTooltip
                      disableHoverListener={!this.state.barInfo.trial}
                      disableFocusListener={!this.state.barInfo.trial}
                      disableTouchListener={!this.state.barInfo.trial}
                      className="tooltipSub"
                      title={t("main.unavailable")}
                      placement="top"
                      arrow
                    > */}
                    <div>
                      <li
                        // className={`boxShadow  ${
                        //   this.state.barInfo.trial ? "disabled" : ""
                        // }`}
                        className={`boxShadow`}
                      >
                        <NavLink
                          className="navSelect"
                          onClick={() => this.pageChange("Billing")}
                          to={{
                            pathname: "/billing",
                            info: this.state.info,
                          }}
                        >
                          {t("main.billing")}
                        </NavLink>
                      </li>
                    </div>
                    <div>
                      <li
                        // className={`boxShadow  ${
                        //   this.state.barInfo.trial ? "disabled" : ""
                        // }`}
                        className={`boxShadow`}
                      >
                        <NavLink
                          className="navSelect"
                          onClick={() => this.openModal()}
                          to={{
                            pathname: "/overview",
                            info: "",
                          }}
                        >
                          Action
                        </NavLink>
                      </li>
                    </div>
                    {/* </CustomTooltip> */}
                    {/* <div>
                      <li className={`boxShadow `}>
                        <NavLink
                          className="navSelect"
                          onClick={() => this.pageChange("Subscription")}
                          to={{
                            pathname: "/subscription",
                            info: this.state.info,
                          }}
                        >
                          {t("main.subscription")}
                        </NavLink>
                      </li>
                    </div> */}
                  </ul>
                </div>

                {/* <Button variant="primary" onClick={this.handleShow}>
                  Launch demo modal
                </Button> */}

                <div className="contentBox">
                  <Switch>
                    <Route path="/overview" component={Overview} />
                    <Route path="/events" component={Events} />
                    <Route path="/billing" component={Fakturering} />
                    {/* <Route path="/subscription" component={Prenumeration} /> */}
                    <Route path="/event" component={Event} />
                  </Switch>
                </div>
              </div>
              {/* <button onClick={this.openModal} className="open-modal-btn">
                Open Modal
              </button> */}

              <div
                className={`modal ${this.state.visible ? "modal-show" : ""}`}
              >
                <div className="modalcontent">
                  <div className="modal-content">
                    <span className="close" onClick={this.closeModal}>
                      &times;
                    </span>
                    <p className="title-tag">Confirmation</p>

                    <p>
                      1. If you want to remove all the bars from your account
                      then select the delete-All option.
                    </p>

                    <p>
                      2. If you want to only selected bars remove from your
                      account then select the delete option.
                    </p>

                    <div>
                      <div
                        className="yellowBtn floatRight"
                        style={{ marginLeft: 10 }}
                        onClick={this.softDeleteSpecificBar}
                      >
                        Delete
                      </div>

                      <div
                        className="yellowBtn floatRight"
                        style={{ marginLeft: 10 }}
                        onClick={this.softDeleteAllBars}
                      >
                        Delete All
                      </div>

                      <div
                        className="yellowBtn floatRight"
                        onClick={this.closeModal}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
