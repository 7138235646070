import React, { Component } from "react";
import { Helmet } from "react-helmet";
import logo from "./images/logo.png";
import barImg from "./images/barImage.png";

import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import arrowWhite from "./icons/arrow-down-white.png";
import placeholder from "./images/barPlaceholderAlt.png";
import { withTranslation } from "react-i18next";
import logoHeader from "./images/logoheader.png";
import logoHeaderSvg from "./images/logoheadersvg.svg";
import { Alert, Button } from "react-bootstrap";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
// import "bootstrap/dist/css/bootstrap.min.css";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      barInfo: {},
      uid: "",
      userEmail: "",
      url: "",
      warningVar: false,
      newBarTitle: "",
      data: [],
      renderList: (
        <div className="spinnerDiv">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.loadBarDetails();
  }

  loadBarDetails() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const ref = firebase.firestore().collection("owners").doc(user.uid);
        this.setState({ uid: user.uid, userEmail: user.email });

        ref
          .get()
          .then((doc) => {
            if (doc.exists) {
              const barList = doc?.data()?.bars;

              if (barList.length > 0) {
                Promise.all(
                  barList.map(async (element) => {
                    const barDoc = await firebase
                      .firestore()
                      .collection("bars")
                      .doc(element)
                      .get();
                    return barDoc.data();
                  })
                ).then((barData) => {
                  let filterdata = barData?.filter(
                    (item) => item != undefined || item != null
                  );

                  console.log("====================================");
                  console.log("filteredArray" + JSON.stringify(filterdata));
                  console.log("====================================");

                  const fetchURLs = async () => {
                    try {
                      const urlData = await Promise.all(
                        filterdata.map(async (x) => {
                          try {
                            const url = await firebase
                              .storage()
                              .ref("barImages")
                              .child(x.id)
                              .getDownloadURL();

                            return { ...x, newurl: url || placeholder };
                          } catch (error) {
                            console.error("Error fetching URL:", error.message);
                            return { ...x, newurl: placeholder }; // If there's an error, use placeholder
                          }
                        })
                      );

                      const newData = urlData.sort((a, b) => {
                        const idA = parseInt(a?.id?.substr(-4), 16); // Convert the last 4 characters of "id" to a number
                        const idB = parseInt(b?.id?.substr(-4), 16);

                        return idA - idB;
                      });

                      this.setState({ data: newData });
                      this.setState({ loading: false });
                    } catch (error) {
                      console.error("Error:", error.message);
                    }
                  };
                  fetchURLs();
                });
              } else {
              }
            } else {
              console.log("kkkkk");
            }
          })
          .catch((error) => {
            console.log("kkkkk" + error);
          });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  toggleOffCanvas() {
    this.setState({ offCanvas: !this.state.offCanvas });
    if (!this.state.offCanvas) {
      document.getElementById("offCanvas").style.right = "0px";
    } else {
      document.getElementById("offCanvas").style.right = "-300px";
    }
  }

  softDeleteOwnerAccount = async () => {
    const uid = firebase.auth().currentUser.uid;
    console.log("uid==>" + JSON.stringify(uid));

    try {
      // Fetch the owner's document
      const ownerDoc = await firebase
        .firestore()
        .collection("owners")
        .doc(uid)
        .get();

      console.log("OwnerDoc==>" + JSON.stringify(ownerDoc));

      if (ownerDoc.exists) {
        const barIds = ownerDoc.data().bars;

        // Soft delete each bar document
        const barSoftDeletions = barIds.map((barId) => {
          return firebase.firestore().collection("bars").doc(barId).update({
            deleted: true,
            deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });

        await Promise.all(barSoftDeletions);

        // Soft delete the owner's document
        await firebase.firestore().collection("owners").doc(uid).update({
          deleted: true,
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Sign out the user
        await firebase.auth().signOut();

        // Redirect to login or home page
        this.props.history.push("/login");
      }
    } catch (error) {
      console.error("Error soft deleting account and bars: ", error.message);
    }
  };

  async addBar() {
    this.setState({ loading: true });
    let renderList = [];
    let length = renderList.length + 1;
    let email = "";
    const uid = firebase.auth().currentUser.uid;

    firebase
      .firestore()
      .collection("owners")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let id = doc.data().bars.pop();
          length = parseInt(id.slice(uid.length)) + 1;

          email = doc.data().email;
        }
      });

    setTimeout(() => {
      const info = {
        adress: "Enter address",
        category: [],
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
        email: email,
        events: [],
        hours: {
          monday: "00:00-00:00",
          tuesday: "00:00-00:00",
          wednesday: "00:00-00:00",
          thursday: "00:00-00:00",
          friday: "00:00-00:00",
          saturday: "00:00-00:00",
          sunday: "00:00-00:00",
        },
        id: this.state.uid + length,
        link: "",
        name: this.state.newBarTitle,
        number: "",
        email: "",
        facebook: "",
        instagram: "",
        food: false,
        student: false,
        subscriptionId: null,
        created: new Date(),
        trial: true,
      };
      let trialInfo = info;
      trialInfo.trial = true;
      renderList.push(
        <div
          className="barSelectDashboard"
          onClick={() => {
            localStorage.setItem("info", JSON.stringify(trialInfo));
            this.props.history.push("/overview");
          }}
        >
          <img
            className="barImgDashboard"
            src={placeholder}
            alt="Bar Image"
          ></img>
          <p className="barSelectTitle">{this.state.newBarTitle}</p>
          <p className="barSelectAddress"></p>
        </div>
      );

      let ref = firebase
        .firestore()
        .collection("bars")
        .doc(this.state.uid + length);
      ref.set(info);

      const ownref = firebase
        .firestore()
        .collection("owners")
        .doc(this.state.uid);

      let bars_new = [];
      ownref.get().then((doc) => {
        if (doc.exists) {
          const bars = doc.data().bars;
          bars_new = [...bars, this.state.uid + length];
          ownref.update({
            bars: bars_new,
          });
        }
      });
    }, 1000);
    setTimeout(() => {
      this.setState({ loading: true });

      this.loadBarDetails();
    }, 1500);
  }

  handleWarning = () => {
    if (!this.state.warningVar) {
      document.getElementById("outerWarning").style.display = "initial";
      this.setState({
        warningVar: true,
      });
    } else {
      document.getElementById("outerWarning").style.display = "none";
    }
    this.setState({
      warningVar: false,
    });
  };

  handleRevert = () => {
    document.getElementById("outerWarning").style.display = "none";
    this.setState({
      warningVar: false,
    });
  };

  regBar() {
    if (!this.state.warningVar) {
      document.getElementById("outerWarning").style.display = "initial";
      this.setState({
        warningVar: true,
      });
    } else {
      document.getElementById("outerWarning").style.display = "none";
    }
  }

  trimString(str, limit) {
    if (typeof str !== "string" || typeof limit !== "number") {
      // Check if input types are valid
      return "Invalid input types";
    }

    // Trim and add ellipsis if necessary
    let newStr = str.trim().substr(0, limit);

    if (str.trim().length > limit) {
      newStr = newStr + " ...";
    }

    return newStr;
  }

  render() {
    const { t } = this.props;
    let hamburgerStyle = this.state.offCanvas
      ? "hamburger hamburger--collapse is-active"
      : "hamburger hamburger--collapse";
    return (
      <div>
        <Helmet>
          <style>{"body { background-color: #F7F7F7 !important; }"}</style>
        </Helmet>
        <div className="outerWarning" id="outerWarning">
          <div className="modalcontent">
            <div className="innerWarning">
              <p className="warningText">{t("dashboard.whatName")}</p>
              <input
                className="longInput contactInput"
                type="text"
                value={this.state.newBarTitle}
                onChange={(event) => {
                  this.setState({ newBarTitle: event.target.value });
                }}
              ></input>
              <button
                className="warningButton goBackBtn "
                onClick={this.handleRevert}
              >
                {t("dashboard.cancel")}
              </button>
              <button
                className="warningButton "
                onClick={() => {
                  this.addBar();
                  this.handleWarning();
                }}
              >
                {t("dashboard.add")}
              </button>
            </div>
          </div>
        </div>
        <div className="header">
          <div className="logoBar">
            <img className="logo" src={logo} alt="Bar Zone logo"></img>
            <div className="logoHeader-content">
              <img
                className="logoHeader"
                src={logoHeaderSvg}
                alt="Bar Zone logo"
              ></img>
            </div>

            <Dropdown className="settingsNav">
              <Dropdown.Toggle
                className="settingsToggle"
                variant="success"
                id="dropdown-basic"
              >
                {this.state.userEmail}
                <img
                  className="arrowDownWhite"
                  src={arrowWhite}
                  alt="arrow"
                ></img>
              </Dropdown.Toggle>
              <Dropdown.Menu className="settingsDrop">
                {/*<Dropdown.Item className="settingsItem item1">Settings</Dropdown.Item>*/}
                <Dropdown.Item
                  className="settingsItem item2"
                  onClick={() => firebase.auth().signOut()}
                >
                  {t("dashboard.logOut")}
                </Dropdown.Item>
                {/* <Dropdown.Item
                  className="settingsItem item2"
                  onClick={() => this.softDeleteOwnerAccount()}
                >
                  {"Delete Account"}
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
            <button
              class={hamburgerStyle}
              type="button"
              onClick={this.toggleOffCanvas.bind(this)}
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div id="offCanvas" className="offCanvas">
            <div className="offCanvasInner">
              <div className="offCanvasLinkDiv">
                {/*<a className="offCanvasLink">Settings</a>*/}
                <a
                  className="offCanvasLink"
                  onClick={() => firebase.auth().signOut()}
                >
                  {t("dashboard.logOut")}
                </a>
              </div>
            </div>
            <div className="offCanvasLogoDiv">
              <img className="logoSmall" src={logo} alt="Bar Zone logo"></img>
            </div>
          </div>
          <div className="titleBar boxShadow">
            <div className="outerTitleBar">
              <div className="innerTitleBar">
                <p className="pageTitle">{t("dashboard.dashboard")}</p>
                <a
                  className="yellowBtn floatRight linkStyle"
                  onClick={() => this.regBar()}
                >
                  {t("dashboard.registerBar")}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="outerDashboard">
          <div className="innerDashboard">
            {/* {this.state.loading == true && (
              
            )} */}
            {this.state.loading == false && this.state.data?.length > 0 ? (
              this.state.data
                .filter((data) => !data.deleted) // Filter out items where deleted is true
                .map((data) => {
                  return (
                    <div
                      className="barSelectDashboard"
                      onClick={() => {
                        localStorage.setItem("info", JSON.stringify(data));
                        this.props.history.push("/overview");
                      }}
                    >
                      <img
                        className="barImgDashboard"
                        src={data?.newurl}
                        alt="Bar Image"
                      ></img>
                      <p className="barSelectTitle">
                        {" "}
                        {this.trimString(data?.name, 21)}
                      </p>
                      <p className="barSelectAddress">
                        {this.trimString(data?.adress, 30)}
                      </p>
                    </div>
                  );
                })
            ) : this.state.loading == true ? (
              <div className="spinnerDiv">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              this.state.data?.length == 0 && (
                <div className="spinnerDiv">
                  <h3>No bars Found :(</h3>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Dashboard);
