import React, { Component } from "react";
import logo from "./images/logoFull.png";
import info from "./icons/info.png";
import { Helmet } from "react-helmet";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { TimePicker } from "@mui/x-date-pickers";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "proxima-nova, sans-serif",
  },
}))(Tooltip);

class RegSub extends Component {
  state = {
    user: "",
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user,
        });
      }
    });
  }

  pickSub(subscription) {
    console.log(firebase.auth().currentUser.uid);
    const { uid } = this.state.user;
    firebase
      .firestore()
      .collection("owners")
      .doc(uid)
      .get()
      .then((doc) => {
        let ownerData = doc.data();
        console.log(ownerData);
        let customer = ownerData.stripeCustomerId;
        let bar = ownerData.bars[0];
        const createRegSubscriptionSession = firebase
          .functions()
          .httpsCallable("createRegSubscriptionSession");
        createRegSubscriptionSession({
          customer: customer,
          plan: subscription,
          bar: bar,
          trial_period_days: 30,
        })
          .then((result) => {
            var stripe = window.Stripe(
              "pk_live_sENe0SInoPYNHICbt6X8JqNo00Zahqk7Ab"
            );
            console.log(result);
            stripe
              .redirectToCheckout({
                sessionId: result.data.session.id,
              })
              .then(function (result) {
                if (result.error != null) {
                  console.log(result.error.message);
                } else {
                  const ref = firebase
                    .firestore()
                    .collection("bars")
                    .doc(uid + "1");
                  ref.update({
                    created: new Date(),
                    subscription: subscription,
                  });
                }
              });
          })
          .catch((e) => {
            console.log(e);
          });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="pickSubOuter">
        <Helmet>
          <style>
            {
              "@media screen and (min-width: 1025px){ .regLogo{display: none !important;}}"
            }
          </style>
        </Helmet>
        <img className="logoOnBoarding regLogo" src={logo} alt="logo"></img>
        <img className="logoOnBoarding pickSubLogo" src={logo} alt="logo"></img>
        <p className="regTitle">{t("regSub.chooseSub")}</p>
        <div className="options">
          <div className="subOuterBoarding">
            <div className="bgInner">
              <div className="subInnerOnBoarding">
                <div className="subTitleDiv">
                  <p className="subTitle">{t("regSub.starter")}</p>
                </div>
                <p className="costTitle">{t("regSub.free")}</p>
                <p className="subTextOnBoarding">{t("regSub.estSeen")}</p>
                <div className="perkDiv">
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.address")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoAddress")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.name")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoName")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.openingHours")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoOpeningHours")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.logo")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoLogo")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText unactive">
                      {t("regSub.phoneNumber")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoNumber")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText unactive">
                      {t("regSub.happyHours")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoHappyHours")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText unactive">{t("regSub.linkTo")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoLinkTo")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText unactive">
                      {t("regSub.eventsRealTime")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoEvents")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              <button
                className="subBtnOnBoarding"
                onClick={() => this.pickSub(0)}
              >
                {t("regSub.choosePlan")}
              </button>
            </div>
          </div>
          <div className="subOuterBoarding">
            <div className="bgInner">
              <div className="subInnerOnBoarding">
                <div className="subTitleDiv">
                  <p className="subTitle">{t("regSub.premium")}</p>
                </div>
                <p className="costTitle">
                  4.99€<span className="monthSpan"> {t("regSub.month")}</span>
                </p>
                <p className="subTextOnBoarding">{t("regSub.estSeen")}</p>
                <div className="perkDiv">
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.address")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoAddress")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.name")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoName")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.openingHours")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoOpeningHours")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.logo")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoLogo")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText ">{t("regSub.phoneNumber")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoNumber")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText ">{t("regSub.happyHours")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoHappyHours")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText unactive">{t("regSub.linkTo")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoLinkTo")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText unactive">
                      {t("regSub.eventsRealTime")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoEvents")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                </div>
              </div>

              <button
                className="subBtnOnBoarding"
                onClick={() => this.pickSub(1)}
              >
                {t("regSub.choosePlan")}
              </button>
            </div>
          </div>
          <div className="subOuterBoarding">
            <div className="bgInner">
              <div className="subInnerOnBoarding">
                <div className="subTitleDiv">
                  <p className="subTitle">{t("regSub.vip")}</p>
                </div>
                <p className="costTitle">
                  8.99€<span className="monthSpan">{t("regSub.month")}</span>
                </p>
                <p className="subTextOnBoarding">{t("regSub.estSeen")}</p>
                <div className="perkDiv">
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.address")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoAddress")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.name")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoName")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.openingHours")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoOpeningHours")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.logo")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoLogo")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.phoneNumber")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoNumber")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.happyHours")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoHappyHours")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.linkTo")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoLinkTo")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText">{t("regSub.eventsRealTime")}</p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("regSub.infoEvents")}
                      placement="top"
                      arrow
                    >
                      <img className="infoIcon" src={info} alt="info"></img>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              <button
                className="subBtnOnBoarding"
                onClick={() => this.pickSub(2)}
              >
                {t("regSub.choosePlan")}
              </button>
            </div>
          </div>
        </div>
        <div className="trialDivOnboarding">
          <p className="trialMessageOnboarding">{t("regSub.30daysIncl")}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegSub);
