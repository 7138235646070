import React, { Component } from "react";
import info from "./icons/info.png";
import { Helmet } from "react-helmet";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "proxima-nova, sans-serif",
  },
}))(Tooltip);

class Prenumeration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: 0,
      info: {},
      warningVar: false,
      prevSub: 0,
      loading: false,
    };
  }

  componentDidMount() {
    const data = localStorage.getItem("info");
    const info = JSON.parse(data);
    this.setState({ info: info, subscription: info.subscription });
    this.subText();
  }

  handleSubChange = (event, sub) => {
    this.setState({ loading: true });
    let user = firebase.auth().currentUser;

    if (
      this.state.info.subscriptionId === undefined ||
      this.state.info.subscriptionId === null ||
      this.state.info.subscriptionId == ""
    ) {
      firebase
        .firestore()
        .collection("owners")
        .where("email", "==", user.email)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let customer = doc.data().stripeCustomerId;
            const createSubscriptionSession = firebase
              .functions()
              .httpsCallable("createSubscriptionSession");
            console.log(this.state.info);
            createSubscriptionSession({
              customer: customer,
              plan: sub,
              bar: this.state.info.id,
            })
              .then((result) => {
                var stripe = window.Stripe(
                  "pk_live_sENe0SInoPYNHICbt6X8JqNo00Zahqk7Ab"
                );
                console.log(result);
                stripe
                  .redirectToCheckout({
                    sessionId: result.data.session.id,
                  })
                  .then(function (result) {
                    if (result.error != null) {
                      console.log(result.error.message);
                    } else {
                      this.setState({
                        subscription: parseInt(result.data.plan),
                        info: {
                          ...this.state.info,
                          subscription: parseInt(result.data.plan),
                          subscriptionId: result.data.subscriptionId,
                        },
                        loading: false,
                      });
                      const ref = firebase
                        .firestore()
                        .collection("bars")
                        .doc(this.state.info.id);
                      ref.update({
                        subscription: parseInt(result.data.plan),
                        subscriptionId: result.data.subscriptionId,
                      });
                      let info = this.state.info;
                      info.subscription = parseInt(result.data.plan);
                      localStorage.setItem("info", JSON.stringify(info));
                    }
                  });
              })
              .catch((e) => {
                console.log(e);
                this.setState({ loading: false });
              });
          });
        });
    } else {
      const subscriptionChange = firebase
        .functions()
        .httpsCallable("subscriptionChange");
      const oldPlan = this.state.subscription ? this.state.subscription : 0;

      subscriptionChange({
        subscriptionId: this.state.info.subscriptionId,
        newPlan: sub,
        oldPlan: oldPlan,
        bar: this.state.info.id,
      })
        .then((result) => {
          this.setState({
            subscription: parseInt(result.data.plan),
            info: {
              ...this.state.info,
              subscription: parseInt(result.data.plan),
            },
            loading: false,
          });
          const ref = firebase
            .firestore()
            .collection("bars")
            .doc(this.state.info.id);
          ref.update({
            subscription: parseInt(result.data.plan),
          });
          let info = this.state.info;
          localStorage.setItem("info", JSON.stringify(info));
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    }
  };

  subText() {
    if (this.state.loading) {
      return ["Loading", "Loading", "Loading"];
    }
    switch (this.state.subscription) {
      case 0:
        return ["Current plan", "Choose plan", "Choose plan"];
      case 1:
        return ["Choose plan", "Current plan", "Choose plan"];
      case 2:
        return ["Choose plan", "Choose plan", "Current plan"];
      default:
        return ["Choose plan", "Choose plan", "Choose plan"];
    }
  }

  handleWarning = () => {
    if (!this.state.warningVar) {
      document.getElementById("outerWarning").style.display = "initial";
      this.setState({
        warningVar: true,
      });
    } else {
      document.getElementById("outerWarning").style.display = "none";
    }
    let sub = this.state.subscription;
    const ref = firebase.firestore().collection("bars").doc(this.state.info.id);
    ref.update({
      subscription: sub,
    });
    let info = this.state.info;
    info.subscription = sub;
    localStorage.setItem("info", JSON.stringify(info));
    this.componentDidMount();
    this.setState({
      warningVar: false,
    });
  };

  handleRevert = () => {
    document.getElementById("outerWarning").style.display = "none";
    this.setState({ subscription: this.state.prevSub });
    this.componentDidMount();
    this.setState({
      warningVar: false,
    });
  };
  willBeCancelled = (cond) => {
    if (!cond) return;
    return (
      <p>
        This plan will be cancelled and you will be reverted to the free plan.
      </p>
      //<p>{t('subscription.planCancelled')}</p>
    );
  };
  render() {
    const { t } = this.props;
    const subText = this.subText();
    return (
      <div>
        <Helmet>
          <style>{"body { background-color: #F7F7F7 !important; }"}</style>
        </Helmet>

        <div className="outerWarning" id="outerWarning">
          <div className="innerWarning">
            <p className="warningText">{t("subscription.changeSub")}</p>
            <button
              className="warningButton goBackBtn boxShadow"
              onClick={this.handleRevert}
            >
              {t("subscription.goBack")}
            </button>
            <button
              className="warningButton boxShadow"
              onClick={this.handleWarning}
            >
              Continue
            </button>
          </div>
        </div>
        <p className="tabTitle">{t("subscription.subscription")}</p>
        <div className="content contentWide backgroundShift boxShadow">
          {/*<div className="trialMessageDiv">
                        <p className="trialMessage">30 days free V.I.P subscription is included in all plans.</p>
                    </div>*/}
          <div className="subDistribution">
            <div className="subOuter">
              <div className="subInner">
                <div className="subTitleDiv">
                  <p className="subTitle">{t("subscription.starter")}</p>
                </div>
                <p className="costTitle">{t("subscription.free")}</p>
                <p className="subText">{t("subscription.estSeen")}</p>
                <div className="perkDiv">
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash"></p>
                    </div>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.name")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoName")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.address")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoAddress")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.openingHours")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoOpeningHours")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.linkTo")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoLinkTo")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.eventReach-out")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.eventReach-out")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.manageAccount")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.manageAccount")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              <a
                // className={
                //   "yellowBtn linkStyle subBtn"(
                //     this.state.subscription === 0 ? "currentBtn" : ""
                //   ) + (this.state.info.trial ? " disabled" : "")
                // }
                className="yellowBtn linkStyle subBtn disabled"
                // +(this.state.subscription === 2 ? "currentBtn" : "") +
                // (this.state.info.trial ? " disabled" : "")

                ref="starter"
                title="starter"
                onClick={(event) => {
                  if (this.state.subscription !== 0 && !this.state.loading) {
                    this.handleSubChange(event, 0);
                  }
                }}
              >
                {subText[0]}
              </a>
            </div>

            <div className="subOuter">
              <div className="subInner">
                <div className="subTitleDiv">
                  <p className="subTitle">{t("subscription.premium")}</p>
                </div>
                <p className="costTitle">
                  2.99€<span className="monthSpan"> /mon</span>
                </p>
                <p className="subText">{t("subscription.estSeen")}</p>
                <div className="perkDiv">
                  {/* <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.name")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoName")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.address")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoAddress")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.openingHours")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoOpeningHours")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.linkTo")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoLinkTo")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.eventReach-out")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.eventReach-out")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.manageAccount")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.manageAccount")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div> */}
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        Free +{/* {t("subscription.favourite&checkes")} */}
                      </p>
                    </div>
                  </div>

                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.favourite&checkes")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.favourite&checkes")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.barZonelogo")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.barZonelogo")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.barZoneCoin")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.barZoneCoin")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <div className="maxwidthText">
                      <p className="perkText perkTextDash">
                        {t("subscription.vipEvents")}
                      </p>
                    </div>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.vipEvents")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              {this.willBeCancelled(
                this.state.subscription === 1 &&
                  this.state.info.subscriptionWillBeCancelled
              )}
              <a
                // className={
                //   "yellowBtn linkStyle subBtn " +
                //   (this.state.subscription === 1 ? "currentBtn" : "") +
                //   (this.state.info.trial ? " disabled" : "")
                // }
                className="yellowBtn linkStyle subBtn disabled"
                // +(this.state.subscription === 2 ? "currentBtn" : "") +
                // (this.state.info.trial ? " disabled" : "")

                ref="premium"
                title="premium"
                onClick={(event) => {
                  if (this.state.subscription !== 1 && !this.state.loading) {
                    this.handleSubChange(event, 1);
                  }
                }}
              >
                {subText[1]}
              </a>
            </div>
            {/* <div className="subOuter">
              <div className="subInner">
                <div className="subTitleDiv">
                  <p className="subTitle">{t("subscription.vip")}</p>
                </div>
                <p className="costTitle">
                  8.99€<span className="monthSpan"> /mon</span>
                </p>
                <p className="subText">{t("subscription.estSeen")}</p>
                <div className="perkDiv">
                  <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.address")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoAddress")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.name")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoName")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.openingHours")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoOpeningHours")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash">
                      {t("subscription.logo")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoLogo")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash ">
                      {t("subscription.phoneNumber")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoNumber")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash ">
                      {t("subscription.happyHours")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoHappyHours")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash ">
                      {t("subscription.linkTo")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoLinkTo")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                  <div className="perkRow">
                    <p className="perkText perkTextDash ">
                      {t("subscription.eventsRealTime")}
                    </p>
                    <CustomTooltip
                      className="tooltipSub"
                      title={t("subscription.infoEvents")}
                      placement="top"
                      arrow
                    >
                      <img
                        className="infoIcon infoIconDash"
                        src={info}
                        alt="info"
                      ></img>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              {this.willBeCancelled(
                this.state.subscription === 2 &&
                  this.state.info.subscriptionWillBeCancelled
              )}
              <a
                className="yellowBtn linkStyle subBtn disabled"
                // +(this.state.subscription === 2 ? "currentBtn" : "") +
                // (this.state.info.trial ? " disabled" : "")

                title="vip"
                ref="vip"
                onClick={(event) => {
                  if (this.state.subscription !== 2 && !this.state.loading) {
                    this.handleSubChange(event, 2);
                  }
                }}
              >
                {subText[2]}
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Prenumeration);
