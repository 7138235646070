import React, { Component } from "react";
import logo from "./images/logoFull.png";
import { withTranslation } from 'react-i18next';




class AboutUs extends Component {
    constructor(props) {
        super(props);
    }




render() {
        const { t } = this.props;
        return (
            <div className="">
                <div className="innerReg">
                    <img className="logo" src={logo} alt="logo"></img>
                    <div className="aboutUsDiv">
                        <p className="privacyTitle">{t('aboutUs.welcome')}</p>
                        <p className="aboutDesc">{t('aboutUs.presentToYou')}</p>
                        <p className="aboutDesc">{t('aboutUs.withBarzone')}</p>
                        <p className="aboutDesc">{t('aboutUs.registerYour')}</p>
                       {/*<p className="aboutDescYellow">DUE TO THE CIRCUMSTANCES OF COVID-19, THE APPLICATION IS FREE OF USE UNTIL THE 1ST OF JULY, AFTER THAT ANYONE WHO SIGNS UP FOR A SUBSCRIPTION WILL AUTOMATICALLY GET ONE FREE MONTH V.I.P.</p>
                        <p className="aboutDescBold">- FREE: (0,00 €)</p>
                        <p className="aboutDesc">- YOUR ESTABLISHMENT WILL BE SEEN ON THE MAP BY ALL USERS WITH YOUR ADRESS, LOGO, OPENING HOURS (1st MONTH FREE V.I.P. SUBSCRIPTION).</p>
                        <p className="aboutDescBold">- PREMIUM: (4,99 €/ month)</p>
                        <p className="aboutDesc">- ON MAP AND SEEN BY ALL USERS WITH YOUR ADRESS, LOGO, OPENING HOURS +PHONE NUMBER + HAPPY HOURS IN REAL TIME. (1st MONTH FREE V.I.P. SUBSCRIPTION).</p>
                        <p className="aboutDescBold">- V.I.P: (8,99 €/ month)</p>
                        <p className="aboutDesc">- ON MAP AND SEEN BY ALL USERS WITH YOUR ADRESS, LOGO, OPENING HOURS + PHONE NUMBER + HAPPY HOURS + LINK TO YOUR HOME PAGE + CONCERTS / LIVE SPORT / AFTER-WORKS /KARAOKÉS/ DJ´S / TASTINGS / AND ALL EVENTS IN REAL TIME (1st MONTH FREE SUBSCRIPTION).</p>
                        */}
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(AboutUs);
