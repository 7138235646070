import React, { Component } from "react";
import logo from "./images/logoFull.png";
import { Helmet } from "react-helmet";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/functions"; // Add this line

import firebase from "firebase/compat/app";
import Spinner from "react-bootstrap/Spinner";
import { withTranslation } from "react-i18next";

class RegAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      fname: "",
      lname: "",
      password: "",
      rePassword: "",
      errorText: "",
      loading: false,
    };
  }
  createUser() {
    console.log("error===" + JSON.stringify("karan"));

    if (this.state.password.length < 8) {
      this.setState({
        errorText: "Your password must be at least 8 characters long.",
      });
    } else {
      if (this.state.password !== this.state.rePassword) {
        this.setState({ errorText: "The passwords must be the same." });
      } else {
        if (
          this.state.email === "" ||
          this.state.fname === "" ||
          this.state.lname === "" ||
          this.state.password === "" ||
          this.state.rePassword === ""
        ) {
          this.setState({ errorText: "All entries must be filled in." });
        } else {
          this.setState({ loading: true });
          var createStripeCustomer = firebase
            .functions()
            .httpsCallable("createStripeCustomer");
          createStripeCustomer({
            email: this.state.email,
            name: this.state.fname + " " + this.state.lname,
          })
            .then((customer) => {
              firebase
                .auth()
                .createUserWithEmailAndPassword(
                  this.state.email,
                  this.state.password
                )
                .then((user) => {
                  const uid = firebase.auth().currentUser.uid;
                  const ref = firebase
                    .firestore()
                    .collection("owners")
                    .doc(uid);

                  ref.set({
                    fname: this.state.fname,
                    lname: this.state.lname,
                    email: this.state.email.toLowerCase(),
                    stripeCustomerId: customer.data.customer.id,
                  });
                  this.props.history.push("/register-bar");
                })
                .catch((error) => {
                  if (
                    error.message ===
                    "The email address is already in use by another account."
                  ) {
                    this.setState({
                      errorText:
                        "There already exists an account with this email.",
                    });
                    this.setState({ loading: false });
                  } else if (
                    error.message === "The email address is badly formatted."
                  ) {
                    this.setState({
                      errorText: "The email is not correctly formatted",
                    });
                    this.setState({ loading: false });
                  } else {
                    this.setState({
                      errorText: "Something went wrong, try again.",
                    });
                    this.setState({ loading: false });
                  }
                  console.error(error);
                });
            })
            .catch((e) => {
              console.log("error===" + JSON.stringify(e));
            });
        }
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <style>
            {
              ".spinner-border{border-color: white !important; border-right-color: transparent !important;}"
            }
          </style>
        </Helmet>
        <div className="innerReg">
          <img className="logoOnBoarding regLogo" src={logo} alt="logo"></img>
          <p className="regTitle">{t("createAccount.createAccount")}</p>
          <div className="">
            <input
              className="onBoardingInput"
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
              placeholder={t("createAccount.email")}
            ></input>
            <input
              className="onBoardingInput"
              value={this.state.fname}
              onChange={(event) => this.setState({ fname: event.target.value })}
              placeholder={t("createAccount.firstName")}
            ></input>
            <input
              className="onBoardingInput"
              value={this.state.lname}
              onChange={(event) => this.setState({ lname: event.target.value })}
              placeholder={t("createAccount.lastName")}
            ></input>
            <input
              className="onBoardingInput"
              type="password"
              value={this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              placeholder={t("createAccount.password")}
            ></input>
            <input
              className="onBoardingInput"
              type="password"
              value={this.state.rePassword}
              onChange={(event) =>
                this.setState({ rePassword: event.target.value })
              }
              placeholder={t("createAccount.repeatPass")}
            ></input>
            <p className="errorText">{this.state.errorText}</p>
          </div>
          <button
            className="buttonOnBoarding"
            onClick={() => this.createUser()}
          >
            {t("createAccount.next")}
          </button>

          {this.state.loading && (
            <div className="spinnerDivOnBoarding">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegAccount);
