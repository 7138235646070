import React, { Component } from "react";

import logo from "./images/logoFull.png";

import { withTranslation } from "react-i18next";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
class LogIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorText: "",
    };
  }

  signIn() {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase
          .auth()
          .signInWithEmailAndPassword(this.state.email, this.state.password)
          .then((user) => {
            console.log("user=======>" + JSON.stringify(user));
            const uid = user.user.uid;
            const ref = firebase.firestore().collection("owners").doc(uid);
            ref
              .get()
              .then((doc) => {
                if (doc?.exists) {
                  if (doc.data().deleted) {
                    // Account is soft-deleted, prevent login
                    console.log("Fail 1: Account is soft-deleted");
                    firebase.auth().signOut();
                    this.setState({
                      errorText:
                        "This account has been deleted. Please contact support if this is a mistake.",
                    });
                  } else {
                    // Account exists and is not deleted
                    this.props.history.push("/dashboard");
                  }
                } else {
                  console.log("Fail 1");
                  firebase.auth().signOut();
                  this.setState({
                    errorText:
                      "There exists no user with the entered email or password.",
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  errorText: "Something went wrong, try again later.",
                });
              });
          })
          .catch((error) => {
            this.setState({
              errorText:
                "There exists no user with the entered email or password.",
            });
          });
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error.message);
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="">
        <div className="innerReg">
          <img className="logo regLogo" src={logo} alt="logo"></img>
          <p className="regTitle">Log in</p>
          <div className="loginDiv">
            <input
              className="onBoardingInput"
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
              placeholder={t("logIn.logIn")}
            ></input>
            <input
              className="onBoardingInput"
              type="password"
              value={this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              placeholder={t("logIn.password")}
            ></input>
            <p className="errorText">{this.state.errorText}</p>
          </div>
          <button className="buttonOnBoarding" onClick={() => this.signIn()}>
            {t("logIn.next")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LogIn);
