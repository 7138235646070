import React, { Component } from "react";

import logo from "./images/logoFull.png";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import { withTranslation } from "react-i18next";
import firebase from "firebase/compat/app";

class ForgotPass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      answer: "",
    };
  }

  resetPass = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(this.state.email);
      this.props.history.push("/login");
    } catch (error) {
      if (
        error.message ==
        "There is no user record corresponding to this identifier. The user may have been deleted."
      ) {
        this.setState({ answer: "No user exists with this email." });
      } else {
        this.setState({ answer: error.message });
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="">
        <div className="innerReg">
          <img className="logo regLogo" src={logo} alt="logo"></img>
          <p className="regTitle">{t("forgotPass.resetPass")}</p>
          <div className="">
            <input
              className="onBoardingInput"
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
              placeholder={t("forgotPass.email")}
            ></input>
            <p className="errorText">{this.state.answer}</p>
          </div>
          <button className="buttonOnBoarding" onClick={() => this.resetPass()}>
            {t("forgotPass.reset")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ForgotPass);
